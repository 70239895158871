import React, { useState } from "react"
import { HeadFC, Link } from "gatsby"
import { graphql } from "gatsby"
import { motion, useTransform } from "framer-motion"
import styled from "styled-components"

// components
import Layout from "../layout/Layout"

import { AllDataT } from "../props-types"
import GalleryItem from "../components/GalleryItem"

const changeBgColor = (selectedColor: string | undefined) => {
	return
}

const IndexPage = ({ data }: AllDataT) => {
	// data processing
	const itemsData = data.Projects.nodes

	// styled components
	const Gallery_Container = styled(motion.div)`
		margin: 0 auto;
		padding: var(--page-margin);
		max-width: 1728px;

		@media only screen and (max-width: 600px) {
			padding: var(--page-margin-mobile);
		}

		@media only screen and (min-width: 600px) and (max-width: 1440px) {
			padding: var(--page-margin-tablet);
		}
	`

	return (
		<Layout bgColor="#FEFEFE">
			<Gallery_Container
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
				transition={{ delay: 0.8, duration: 0.5, staggerChildren: 1 }}
			>
				{itemsData.map((item, i) => {
					const projectUrl = "archives/" + item.slug
					return (
						<Link to={projectUrl}>
							<GalleryItem
								assetType={item.hero.asset.mimeType}
								assetGastsbyImageData={
									item.hero.asset.localFile != null
										? item.hero.asset.localFile
												.childImageSharp.gatsbyImageData
										: undefined
								}
								assetUrl={item.hero.asset.url}
								assetAlt={item.hero.asset.alternativeText}
								assetColumnStart={
									item.hero.mediaLayout.columnStart
								}
								assetColumnEnd={item.hero.mediaLayout.columnEnd}
								assetAlignSelf={item.hero.mediaLayout.alignSelf}
								hasInfo={item.hero.hasCaption}
								infoTitle={item.name}
								infoSubtitle={item.client}
								infoCaption={item.type}
								infoSubCaption={item.year}
								captionColumnStart={item.hero.captionLayout.columnStart}
								captionColumnEnd={item.hero.captionLayout.columnEnd}
								captionAlignSelf={item.hero.captionLayout.alignSelf}
								key={i}
							/>
						</Link>
					)
				})}
			</Gallery_Container>
		</Layout>
	)
}

export default IndexPage

export const Head: HeadFC = () => <title>tom kwok</title>

export const query = graphql`
	query {
		Projects: allGraphCmsProject(sort: { fields: projectId, order: ASC }) {
			nodes {
				projectId
				name
				slug
				client
				type
				year
				hero {
					hasCaption
					asset {
						localFile {
							childImageSharp {
								gatsbyImageData
							}
						}
						alternativeText
						mimeType
						url
					}
					mediaLayout {
						columnStart
						columnEnd
						alignSelf
					}
					captionLayout {
						columnStart
						columnEnd
						alignSelf
					}
				}
			}
		}
	}
`
